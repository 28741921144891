.category {
    flex-wrap: wrap;
    margin-top: 80px !important;
}

.category .card {
    background-color: rgba(128, 128, 128, 0.097);
    width: 18rem;
}

.category .card-name-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.category .card-price {
    color: green;
    font-weight: bold;
}

.category button {
    border-radius: 0;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}

.category .card-text {
    color: rgb(90, 89, 89);
}

.category .card img:hover {
    transform: scale(0.9);
}

.category .card-img-top {
    height: 300px !important;
}